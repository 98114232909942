@import '../../variables.scss';

.btn {
  border: none;
  outline: none;
  padding: 1rem 2rem;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 50px;
  background-color: $primary-color;
  color: #fff;
  transition: all 0.5s;
  text-decoration: none;
  font-size: 0.85rem;

  &:hover {
    cursor: pointer;
    background: darken($color: $primary-color, $amount: 10);
  }
}
