@import '../../variables.scss';

.hero {
  min-height: 650px;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      width: 45%;

      h1 {
        font-size: 3rem;
        margin-bottom: 30px;
      }

      p {
        margin-bottom: 50px;
        line-height: 2rem;

        mark {
          font-weight: bold;
          text-transform: capitalize;
          background-color: transparent;
          color: $primary-color;
          letter-spacing: 0.1rem;
        }
      }
    }

    .hero-img {
      width: 50%;

      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    & .container .content h1 {
      font-size: 2.3rem;
    }
  }

  @media screen and (max-width: 900px) {
    & .container {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 30px;
      padding-bottom: 20px;

      .content {
        width: 80%;
        margin-bottom: 50px;
      }

      .hero-img {
        width: 100%;
        text-align: center;

        img {
          max-width: 600px;
          width: 90%;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    & .container .content {
      width: 100%;

      h1 {
        font-size: 2rem;
      }
    }
  }
}
