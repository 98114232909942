@import '../../variables.scss';

footer {
  background: url('http://www.pngall.com/wp-content/uploads/2017/05/World-Map-Free-PNG-Image.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // padding: 3rem 0 1rem 0;
  position: relative;
  z-index: 1;
  color: #b8b8b8;
  // text-transform: capitalize;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000e22, $alpha: 0.95);
    z-index: -1;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  ul {
    li {
      list-style-type: none;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      i.fas {
        width: 25px;
      }

      a.mail {
        text-transform: lowercase;
      }

      &.socials {
        margin-top: 20px;

        a {
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          background-color: #fff;
          text-align: center;
          border-radius: 50px;
          color: #000;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 20px;
    color: #fff;
  }

  a {
    text-decoration: none;
    color: #b8b8b8;

    &:hover {
      text-decoration: underline;
    }
  }

  .copyright {
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem;

    .container {
      display: block;

      hr {
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    & {
      .container {
        flex-direction: column;
        text-align: center;

        & > div:not(:last-child) {
          margin-bottom: 50px;
          padding-bottom: 50px;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
}
