@import '../../variables.scss';

.service-item {
  text-align: center;
  width: 220px;
  background-color: #fff;
  margin: 10px;
  padding: 1.5rem 0.7rem;
  height: 190px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 43, 91, 0.5);
  transition: all 0.5s ease;
  // background: linear-gradient(276deg, rgba(0,169,215,1) 27%, rgba(0,187,215,1) 78%);

  .icon {
    margin-bottom: 20px;
  }

  span.title {
    text-transform: capitalize;
    font-size: 1.2rem;
    font-weight: 500;
    color: #000;
    text-decoration: none;
  }

  p {
    width: 100% !important;
    margin-top: 10px;
    font-size: 1rem;
  }

  &:hover {
    background-color: $primary-color;
    color: #fff;
    box-shadow: 0 10px 40px rgba(0, 43, 91, 0.5);

    .icon {
      svg path {
        fill: #fff;
      }
    }

    span.title {
      color: #fff;
    }
  }

  @media screen and (max-width: 1200px) {
    & {
      width: 200px;
    }
  }

  @media screen and (max-width: 1100px) {
    & {
      width: 175px;
      a.title {
        font-size: 1rem;
      }

      p {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    & {
      width: 220px;

      a.title {
        font-size: 1.2rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }
}
