@import "../../variables.scss";

.navbar {
  text-transform: uppercase;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    color: #000;
    text-decoration: none;
  }

  .brand-logo {
    height: 50px;
    display: flex;
    align-items: center;

    img {
      width: 60px;
      vertical-align: top;
    }

    span {
      font-size: 1.5rem;
      margin-top: 5px;
      display: inline-block;
      margin-left: 10px;
      color: $primary-color;
    }
  }

  ul.links {
    li {
      display: inline-block;
      list-style-type: none;
      font-size: 0.9rem;
      font-weight: 500;

      &:not(:last-child) {
        margin-right: 40px;
      }

      a {
        transition: all 0.5s;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
