// google fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');

@import './variables.scss';

* {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  // font-size: 1.1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.container {
  max-width: 1280px;
  width: 90%;
  margin: auto;
}

body {
  background-color: #fdfdfd;
}

p {
  font-size: 1.1rem;
  line-height: 1.5rem;

  &.gray-text {
    color: #8a8a8a;
  }
}

/* products section */
.products {
  padding: 3rem 0;

  .container {
    display: flex;
    justify-content: space-around;

    .item {
      a {
        img {
          width: 80px;
          filter: grayscale(100%);
          opacity: 0.5;
          transition: all 0.5s ease;
        }

        &:hover {
          img {
            filter: none;
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    & .container .item a img {
      width: 50px;
    }
  }
}

/* section */
.section {
  padding: 6rem 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .left-side {
      width: 50%;
    }

    .right-side {
      width: 50%;
    }

    h1.title {
      font-size: 1rem;
      text-transform: uppercase;
      color: $primary-color;
    }

    h2.subtitle {
      font-size: 1.8rem;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 80%;
    }

    p {
      width: 80%;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      .left-side {
        width: 100%;
        margin-bottom: 50px;
      }

      .right-side {
        width: 100%;
      }

      .section-img {
        max-width: 600px;
        width: 90%;
        margin: auto;
        display: block;
      }
    }
  }
}

.services-list {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1000px) {
    & {
      justify-content: center;
    }
  }
}
