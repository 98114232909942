@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
* {
  margin: 0;
  padding: 0;
  font-family: Roboto; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500; }

.container {
  max-width: 1280px;
  width: 90%;
  margin: auto; }

body {
  background-color: #fdfdfd; }

p {
  font-size: 1.1rem;
  line-height: 1.5rem; }
  p.gray-text {
    color: #8a8a8a; }

/* products section */
.products {
  padding: 3rem 0; }
  .products .container {
    display: flex;
    justify-content: space-around; }
    .products .container .item a img {
      width: 80px;
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      opacity: 0.5;
      transition: all 0.5s ease; }
    .products .container .item a:hover img {
      -webkit-filter: none;
              filter: none;
      opacity: 1; }
  @media screen and (max-width: 700px) {
    .products .container .item a img {
      width: 50px; } }

/* section */
.section {
  padding: 6rem 0; }
  .section .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .section .container .left-side {
      width: 50%; }
    .section .container .right-side {
      width: 50%; }
    .section .container h1.title {
      font-size: 1rem;
      text-transform: uppercase;
      color: #0000ff; }
    .section .container h2.subtitle {
      font-size: 1.8rem;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 80%; }
    .section .container p {
      width: 80%;
      margin-bottom: 40px; }
    .section .container img {
      width: 100%; }
  @media screen and (max-width: 1000px) {
    .section .container .left-side {
      width: 100%;
      margin-bottom: 50px; }
    .section .container .right-side {
      width: 100%; }
    .section .container .section-img {
      max-width: 600px;
      width: 90%;
      margin: auto;
      display: block; } }

.services-list {
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 1000px) {
    .services-list {
      justify-content: center; } }

.navbar {
  text-transform: uppercase;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .navbar a {
    color: #000;
    text-decoration: none; }
  .navbar .brand-logo {
    height: 50px;
    display: flex;
    align-items: center; }
    .navbar .brand-logo img {
      width: 60px;
      vertical-align: top; }
    .navbar .brand-logo span {
      font-size: 1.5rem;
      margin-top: 5px;
      display: inline-block;
      margin-left: 10px;
      color: #0000ff; }
  .navbar ul.links li {
    display: inline-block;
    list-style-type: none;
    font-size: 0.9rem;
    font-weight: 500; }
    .navbar ul.links li:not(:last-child) {
      margin-right: 40px; }
    .navbar ul.links li a {
      transition: all 0.5s; }
      .navbar ul.links li a:hover {
        color: #0000ff; }
  .navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.btn {
  border: none;
  outline: none;
  padding: 1rem 2rem;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 50px;
  background-color: #0000ff;
  color: #fff;
  transition: all 0.5s;
  text-decoration: none;
  font-size: 0.85rem; }
  .btn:hover {
    cursor: pointer;
    background: #0000cc; }

.hero {
  min-height: 650px;
  display: flex;
  align-items: center; }
  .hero .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .hero .container .content {
      width: 45%; }
      .hero .container .content h1 {
        font-size: 3rem;
        margin-bottom: 30px; }
      .hero .container .content p {
        margin-bottom: 50px;
        line-height: 2rem; }
        .hero .container .content p mark {
          font-weight: bold;
          text-transform: capitalize;
          background-color: transparent;
          color: #0000ff;
          letter-spacing: 0.1rem; }
    .hero .container .hero-img {
      width: 50%; }
      .hero .container .hero-img img {
        width: 100%; }
  @media screen and (max-width: 1200px) {
    .hero .container .content h1 {
      font-size: 2.3rem; } }
  @media screen and (max-width: 900px) {
    .hero .container {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 30px;
      padding-bottom: 20px; }
      .hero .container .content {
        width: 80%;
        margin-bottom: 50px; }
      .hero .container .hero-img {
        width: 100%;
        text-align: center; }
        .hero .container .hero-img img {
          max-width: 600px;
          width: 90%; } }
  @media screen and (max-width: 600px) {
    .hero .container .content {
      width: 100%; }
      .hero .container .content h1 {
        font-size: 2rem; } }

.service-item {
  text-align: center;
  width: 220px;
  background-color: #fff;
  margin: 10px;
  padding: 1.5rem 0.7rem;
  height: 190px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 43, 91, 0.5);
  transition: all 0.5s ease; }
  .service-item .icon {
    margin-bottom: 20px; }
  .service-item span.title {
    text-transform: capitalize;
    font-size: 1.2rem;
    font-weight: 500;
    color: #000;
    text-decoration: none; }
  .service-item p {
    width: 100% !important;
    margin-top: 10px;
    font-size: 1rem; }
  .service-item:hover {
    background-color: #0000ff;
    color: #fff;
    box-shadow: 0 10px 40px rgba(0, 43, 91, 0.5); }
    .service-item:hover .icon svg path {
      fill: #fff; }
    .service-item:hover span.title {
      color: #fff; }
  @media screen and (max-width: 1200px) {
    .service-item {
      width: 200px; } }
  @media screen and (max-width: 1100px) {
    .service-item {
      width: 175px; }
      .service-item a.title {
        font-size: 1rem; }
      .service-item p {
        font-size: 14px; } }
  @media screen and (max-width: 1000px) {
    .service-item {
      width: 220px; }
      .service-item a.title {
        font-size: 1.2rem; }
      .service-item p {
        font-size: 1rem; } }

footer {
  background: url("http://www.pngall.com/wp-content/uploads/2017/05/World-Map-Free-PNG-Image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  color: #b8b8b8; }
  footer:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 14, 34, 0.95);
    z-index: -1; }
  footer .container {
    display: flex;
    justify-content: space-between; }
  footer ul li {
    list-style-type: none; }
    footer ul li:not(:last-child) {
      margin-bottom: 15px; }
    footer ul li i.fas {
      width: 25px; }
    footer ul li a.mail {
      text-transform: lowercase; }
    footer ul li.socials {
      margin-top: 20px; }
      footer ul li.socials a {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        text-align: center;
        border-radius: 50px;
        color: #000; }
        footer ul li.socials a:not(:last-child) {
          margin-right: 10px; }
  footer h2 {
    margin-bottom: 20px;
    color: #fff; }
  footer a {
    text-decoration: none;
    color: #b8b8b8; }
    footer a:hover {
      text-decoration: underline; }
  footer .copyright {
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem; }
    footer .copyright .container {
      display: block; }
      footer .copyright .container hr {
        margin-bottom: 30px; }
  @media screen and (max-width: 750px) {
    footer .container {
      flex-direction: column;
      text-align: center; }
      footer .container > div:not(:last-child) {
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid #fff; } }

